@import "../../../global/scss/vendor/reset-css";
@import "../../../global/scss/variables";
@import "../../../global/scss/mixins/breakpoint";
@import "../../../global/scss/mixins/browser-targeting";

// img sizes
.desktop {
  .img-1 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-2 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-3 {
    // w: 4000px, h:4500px
    padding-top: 112.50%;
  }

  .img-4 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-5 {
    // w: 3998px, h:4500px
    padding-top: 112.56%;
  }

  .img-6 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-7 {
    // w: 3998px, h:4500px
    padding-top: 112.56%;
  }

  .img-8 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }
}

.tablet, .mobile {
  .img-1 {
    // w: 1125.12px, h:2001.12px
    padding-top: 177.86%;
  }

  .img-2 {
    // w: 1125.12px, h:2001.12px
    padding-top: 177.86%;
  }

  .img-3 {
    // w: 1125.12px, h:3099.84px
    padding-top: 275.51%;
  }

  .img-4 {
    // w: 1125.12px, h:2001.12px
    padding-top: 177.86%;
  }

  .img-5 {
    // w: 1125.12px, h:4095.84px
    padding-top: 364.04%;
  }

  .img-6 {
    // w: 1125.12px, h:2001.12px
    padding-top: 177.86%;
  }

  .img-7 {
    // w: 1125.12px, h:3500.16px
    padding-top: 310.04%;
  }

  .img-8 {
    // w: 1125.12px, h:2001.12px
    padding-top: 177.86%;
  }
}

@import "../../../global/scss/global";
//@import "../../../global/scss/debug";